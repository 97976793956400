<template>
 <div>
   <el-card >
     <el-button type="primary" @click="openUpdate()" style="margin-right: 30px" disabled>{{$t('msg.basicInfo.updateInfo')}}</el-button>
     <el-divider></el-divider>
     <el-row >
       <el-col :span="12" >
         <el-steps :active="active" align-center finish-status="success">
          <el-step :title="$t('msg.track.discharge')" :description="portInfoForDray.dischargeTime" />
          <el-step :title="$t('msg.track.fullGateOut')" :description="portInfoForDray.outGateTime" />
          <el-step :title="$t('msg.track.dWHArrival')" :description="portInfoForDray.actualArrivalTime" />
          <el-step :title="$t('msg.track.swER')" :description="portInfoForDray.emptyReturnTime" />
         </el-steps>
       </el-col>
       <el-col :span="12" >
         <el-descriptions :title="$t('msg.track.basicInfo')" :column="3">
           <el-descriptions-item :label="$t('msg.track.trackingNumber')">{{portInfoForDray.bookId}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.ctnrId')" >{{portInfoForDray.ctnrId}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.mbl')">{{portInfoForDray.mbl}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.client')+ ':'">{{portInfoForDray.client}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.ctnrSize')+ ':'">{{drayOrder.ctnrSize}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.weight')+ ':'">{{drayOrder.ctnrWeight}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.pod')+ ':'">{{drayOrder.port}}</el-descriptions-item>
           <el-descriptions-item label="Service Type: "><el-tag size="small">{{drayOrder.attr}}</el-tag></el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.termLFD')"><el-tag size="small">{{portInfoForDray.lfd}}</el-tag></el-descriptions-item>
           <el-descriptions-item label="Gross Profit:"><el-tag size="small">$ {{grossProfit}}</el-tag></el-descriptions-item>
           <el-descriptions-item label="Bill To:"><el-tag size="small">{{orderRecord.client}}</el-tag></el-descriptions-item>
           <el-descriptions-item label="Pay Term Due:"><el-tag size="small">{{orderRecord.payTerm}}</el-tag></el-descriptions-item>
         </el-descriptions>
       </el-col>
     </el-row>
   </el-card>
   <el-card >
     <el-tabs v-model="activeName">
       <el-tab-pane label="Order Summary" name="Order Summary">
         <el-descriptions :title="$t('msg.track.basicInfo')" :column="4" style="font-size: smaller">
           <el-descriptions-item :label="$t('msg.track.id') + ':'">{{drayOrder.id}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.sslCarrier')+ ':'">{{drayOrder.sslCarrier}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.sealNumber')+ ':'">{{drayOrder.sealNumber}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.eta')+ ':'">{{drayOrder.vesselETA}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.firmsCode')">{{portInfoForDray.firmsCode}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.drayCompany')">{{portInfoForDray.drayCompany}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.destSite')">{{portInfoForDray.warehouseName}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.swDischarge')">{{portInfoForDray.dischargeTime}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.fullGateOut')">{{portInfoForDray.outGateTime}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.swFinalArrival')" >{{portInfoForDray.actualArrivalTime}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.emptyReturnAppointTime')">{{portInfoForDray.emptyReturnAppointTime}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.track.emptyReturn')">{{portInfoForDray.emptyReturnTime}}</el-descriptions-item>
         </el-descriptions>
         <el-descriptions :title="$t('msg.address.deliveryAddress')" :column="6" style="font-size: smaller">
           <el-descriptions-item :label="$t('msg.address.streetOne')+ ':'">{{drayOrder.streetOne}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.address.streetTwo')+ ':'">{{drayOrder.streetTwo}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.address.city')+ ':'">{{drayOrder.city}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.address.state')+ ':'">{{drayOrder.state}}</el-descriptions-item>
           <el-descriptions-item :label="$t('msg.address.zip')+ ':'">{{drayOrder.zip}}</el-descriptions-item>
         </el-descriptions>
       </el-tab-pane>
       <el-tab-pane label="Expenses" name="Expenses">
         <el-card>
           <template #header>
             <span style="font-size: small">{{$t('msg.route.interimAP')}}</span>
             <span style="float: left;color: #009dff;font-size: small">{{$t('msg.account.total')}}: ${{totalAP}}</span>
             <el-button-group size="small" style="float: right;margin-bottom: 30px">
               <el-button type="primary" size="small" @click="rcReview('AP')" style="margin-right: 30px">RateCard Review</el-button>
               <el-button type="primary" size="small" @click="addAP('AP')">{{ $t('msg.basicInfo.addRecord') }}</el-button>
             </el-button-group>
           </template>
           <el-table :data="interimAP" style="font-size: smaller">
             <el-table-column prop="remark" :label="$t('msg.account.chargeType')" align="center">
               <template #default="scope">
                 <el-select v-model="scope.row.remark" placeholder="Cost Category" size="small" Style="font-size: small" @change="getItem(scope.$index,'AP')" v-if="scope.$index+apFlag===-1">
                   <el-option label="Pass Through" value="Pass Through"></el-option>
                   <el-option label="Std Ad Hoc" value="Std Ad Hoc"></el-option>
                   <el-option label="Other" value="Other"></el-option>
                 </el-select>
                 <span v-else>{{scope.row.remark}}</span>
               </template>
             </el-table-column>
             <el-table-column prop="item" :label="$t('msg.rate.item')" align="center">
               <template #default="scope">
                 <el-select v-model="scope.row.item" size="small" @change="handleItemChange(scope.$index,'AP')" v-if="scope.$index+apFlag===-1 && scope.row.remark!=='Other'">
                   <el-option v-for="item in billTemp" :key="item.item" :label="item.item" :value="item.item" />
                 </el-select>
                 <el-input v-model="scope.row.notes" size="small" Style="font-size: small" v-else-if="scope.$index+apFlag===-1 && scope.row.remark==='Other'"></el-input>
                 <span v-else>{{scope.row.item}}</span>
               </template>
             </el-table-column>
             <el-table-column prop="qty" :label="$t('msg.rate.qty')" align="center">
               <template #default="scope">
                 <el-input-number v-model="scope.row.qty" size="small" controls-position="right" :step="1" @change="qtyChange(scope.$index,'AP')" v-if="scope.$index+apFlag===-1"></el-input-number>
                 <span v-else>{{scope.row.qty}}</span>
               </template>
             </el-table-column>
             <el-table-column prop="rate" :label="$t('msg.rate.ratePerUOM')" align="center">
               <template #default="scope">
                 <el-input-number v-model="scope.row.rate" size="small" controls-position="right" :step="1" @change="qtyChange(scope.$index,'AP')" v-if="scope.$index+apFlag===-1 && scope.row.remark==='Other'"></el-input-number>
                 <span v-else>$ {{scope.row.rate}}</span>
               </template>
             </el-table-column>
             <el-table-column prop="finalAmount" :label="$t('msg.account.finalAmount')" align="center">
               <template #default="scope">
                 <span >$ {{scope.row.finalAmount}}</span>
               </template>
             </el-table-column>
             <el-table-column label="AddToAR" align="center">
               <template #default="scope">
                 <el-radio-group v-model="addToAR" v-if="scope.$index+apFlag===-1" :disabled="orderRecord.billStatus!=='NA'&& orderRecord.billStatus!=='AR'">
                   <el-radio label='Y'>Y</el-radio>
                   <el-radio label="N">N</el-radio>
                 </el-radio-group>
                 <span v-else>N/A</span>
               </template>
             </el-table-column>
             <el-table-column :label="$t('msg.rate.Operations')" align="center">
               <template #default="scope">
                 <el-button type="success" size="small" @click="addBillItem(scope.$index,'AP')" plain v-if="scope.$index+apFlag===-1">Confirm</el-button>
                 <el-button type="danger" size="small" @click="cancelUpdateAP('AP')" plain v-if="scope.$index+apFlag===-1">Cancel</el-button>
                 <el-button type="primary" size="small" @click="editAP(scope.$index,'AP')" :disabled="scope.row.remark==='DailyOP'" v-if="scope.$index+apFlag!==-1">Edit</el-button>
               </template>
             </el-table-column>
           </el-table>
         </el-card>
       </el-tab-pane>
       <el-tab-pane label="Account Receivable" name="Account Receivable">
         <el-card>
           <template #header>
             <span style="font-size: small">{{$t('msg.route.interimAR')}}</span>
             <span style="float: left;color: #009dff;font-size: small">{{$t('msg.account.total')}}: ${{totalAR}}</span>
             <el-button-group size="small" style="float: right;margin-bottom: 30px">
               <el-button type="primary" size="small" @click="rcReview('AR')" style="margin-right: 30px">RateCard Review</el-button>
               <el-button type="primary" size="small" @click="addAP('AR')" v-if="orderRecord.billStatus==='NA'||orderRecord.billStatus==='AR'">
                 {{ $t('msg.basicInfo.addRecord') }}
               </el-button>
             </el-button-group>
           </template>
           <el-table :data="interimAR" style="font-size: smaller">
             <el-table-column prop="remark" :label="$t('msg.account.chargeType')" align="center">
               <template #default="scope">
                 <el-select v-model="scope.row.remark" placeholder="Cost Category" size="small" Style="font-size: small" @change="getItem(scope.$index,'AR')" v-if="scope.$index+arFlag===-1">
                   <el-option label="Pass Through" value="Pass Through"></el-option>
                   <el-option label="Std Ad Hoc" value="Std Ad Hoc"></el-option>
                   <el-option label="Other" value="Other"></el-option>
                 </el-select>
                 <span v-else>{{scope.row.remark}}</span>
               </template>
             </el-table-column>
             <el-table-column prop="item" :label="$t('msg.rate.item')" align="center">
               <template #default="scope">
                 <el-select v-model="scope.row.item" size="small" @change="handleItemChange(scope.$index,'AR')" v-if="scope.$index+arFlag===-1 && scope.row.remark!=='Other'">
                   <el-option v-for="item in billTemp" :key="item.item" :label="item.item" :value="item.item" />
                 </el-select>
                 <el-input v-model="scope.row.notes" size="small" Style="font-size: small" v-else-if="scope.$index+arFlag===-1 && scope.row.remark==='Other'"></el-input>
                 <span v-else>{{scope.row.item}}</span>
               </template>
             </el-table-column>
             <el-table-column prop="qty" :label="$t('msg.rate.qty')" align="center">
               <template #default="scope">
                 <el-input-number v-model="scope.row.qty" size="small" controls-position="right" :step="1" @change="qtyChange(scope.$index,'AR')" v-if="scope.$index+arFlag===-1"></el-input-number>
                 <span v-else>{{scope.row.qty}}</span>
               </template>
             </el-table-column>
             <el-table-column prop="rate" :label="$t('msg.rate.ratePerUOM')" align="center">
               <template #default="scope">
                 <el-input-number v-model="scope.row.rate" size="small" controls-position="right" :step="1" @change="qtyChange(scope.$index,'AR')" v-if="scope.$index+arFlag===-1 && scope.row.remark==='Other'"></el-input-number>
                 <span v-else>$ {{scope.row.rate}}</span>
               </template>
             </el-table-column>
             <el-table-column prop="finalAmount" :label="$t('msg.account.finalAmount')" align="center">
               <template #default="scope">
                 <span >$ {{scope.row.finalAmount}}</span>
               </template>
             </el-table-column>
             <el-table-column label="AddToAR" align="center">
               <template #default="scope">
                 <el-radio-group v-model="addToAR" v-if="scope.$index+arFlag===-1">
                   <el-radio label='Y'>Y</el-radio>
                   <el-radio label="N">N</el-radio>
                 </el-radio-group>
                 <span v-else>N/A</span>
               </template>
             </el-table-column>
             <el-table-column :label="$t('msg.rate.Operations')" align="center">
               <template #default="scope">
                 <el-button type="success" size="small" @click="addBillItem(scope.$index,'AR')" plain v-if="scope.$index+arFlag===-1">Confirm</el-button>
                 <el-button type="danger" size="small" @click="cancelUpdateAP('AR')" plain v-if="scope.$index+arFlag===-1">Cancel</el-button>
                 <el-button type="primary" size="small" @click="editAP(scope.$index,'AR')" :disabled="scope.row.remark==='DailyOP'||(orderRecord.billStatus!=='NA'&& orderRecord.billStatus!=='AR')"
                            v-if="scope.$index+arFlag!==-1">Edit</el-button>
               </template>
             </el-table-column>
           </el-table>
           <el-divider></el-divider>
           <el-button type="primary" size="small" @click="createInvoice" v-if="orderRecord.billStatus==='NA'||orderRecord.billStatus==='AR'">Create Invoice</el-button>
           <el-button type="success" size="small" @click="getInvoice" v-else>Get Invoice</el-button>
         </el-card>
       </el-tab-pane>
       <el-tab-pane label="Driver Record" name="Driver Record">
         <el-card >
           <template #header>
             <span style="font-size: small">{{$t('msg.route.driverRecord')}}</span>
             <el-button type="primary" size="small" @click="addRecord" style="float: right">{{ $t('msg.basicInfo.addRecord') }}</el-button>
           </template>
           <el-table :data="driverRecordList" style="font-size: smaller">
             <el-table-column prop="ctnrId" :label="$t('msg.track.ctnrId')" sortable align="center"></el-table-column>
             <el-table-column prop="chassisId" :label="$t('msg.dray.chassisId')" sortable align="center"></el-table-column>
             <el-table-column prop="driverName" :label="$t('msg.account.driverName')" sortable align="center"></el-table-column>
             <el-table-column prop="srvType" :label="$t('msg.account.srvType')" sortable align="center"></el-table-column>
             <el-table-column prop="appTime" :label="$t('msg.account.appTime')" sortable align="center"></el-table-column>
             <el-table-column prop="beginTime" :label="$t('msg.account.beginTime')" sortable align="center"></el-table-column>
             <el-table-column prop="endTime" :label="$t('msg.account.endTime')" sortable align="center"></el-table-column>
             <el-table-column prop="waitBegin" :label="$t('msg.account.waitBegin')" sortable align="center"></el-table-column>
             <el-table-column prop="waitEnd" :label="$t('msg.account.waitEnd')" sortable align="center"></el-table-column>
             <el-table-column prop="mileage" :label="$t('msg.account.mileage')" sortable align="center"></el-table-column>
             <el-table-column :label="$t('msg.rate.Operations')" align="center">
               <template #default="{row}">
                 <el-button type="primary" size="small" @click="editDriverRecord(row)" >Edit</el-button>
               </template>
             </el-table-column>
           </el-table>
         </el-card>
       </el-tab-pane>
       <el-tab-pane label="Documents" name="Documents">
         <el-row :gutter="12">
           <el-col :span="6">
             <el-card v-show="false">  </el-card>
           </el-col>
           <el-col :span="12">
             <el-card shadow="always" center>
               <template #header>
                 <span style="font-size: small">{{$t('msg.basicInfo.fileList')}}</span>
                 <el-button type="primary" size="small" @click="fileUpload" style="float: right">{{ $t('msg.basicInfo.addFile') }}</el-button>
               </template>
               <el-table :data="basicInfoList" style="font-size: smaller">
                 <el-table-column prop="refId" :label="$t('msg.track.id')" align="center" width="50px"></el-table-column>
                 <el-table-column prop="key" :label="$t('msg.basicInfo.key')" align="center" ></el-table-column>
                 <el-table-column prop="value" :label="$t('msg.basicInfo.value')" align="center" width="250px"></el-table-column>
                 <el-table-column prop="remark" :label="$t('msg.rate.remark')" align="center"></el-table-column>
                 <el-table-column :label="$t('msg.rate.Operations')" align="center" width="150px">
                   <template #default="{row}">
                     <el-button type="primary" size="small" @click="imageView(row)" :icon="viewIcon"></el-button>
                     <el-button type="danger" size="small" @click="delHandle(row)" :icon="delIcon" />
                   </template>
                 </el-table-column>
               </el-table>
             </el-card>
           </el-col>
           <el-col :span="6">
             <el-card v-show="false">  </el-card>
           </el-col>
         </el-row>
       </el-tab-pane>
       <el-tab-pane label="Messages" name="Messages">
         <Markdown :title="msgTitle" :detail="msgDetail" @onSuccess="onSuccess"></Markdown>
       </el-tab-pane>
     </el-tabs>
   </el-card>
   <el-dialog title="File Upload" v-model="uploadShow" width="30%" center draggable>
     <el-form :model="uploadForm" enctype="multipart/form-data">
       <el-form-item label="Document Category" prop="docCategory" required style="width: 420px">
         <el-select v-model="uploadForm.docCategory" name="docCategory">
           <el-option label="Accounting Doc" value="Accounting Doc"></el-option>
           <el-option label="Legal Doc" value="Legal Doc"></el-option>
           <el-option label="License" value="License"></el-option>
           <el-option label="Other Record" value="Other Record"></el-option>
         </el-select>
       </el-form-item>
       <el-form-item label="Select File" prop="uploadFile">
         <el-upload class="upload-demo" ref="myUpload" action="" accept=".jpg,.png,.JPG,.PNG,.pdf,.PDF"
                    :limit="1" :auto-upload="false" :file-list="uploadForm.uploadList" :on-change="handleChange">
           <el-button type="primary">select file</el-button>
           <template #tip>
             <div class="el-upload__tip">
               jpg/png/pdf only.
             </div>
           </template>
         </el-upload>
       </el-form-item>
     </el-form>
     <el-button type="primary" style="float: right" @click="submitUpload">
       upload to server
     </el-button>
   </el-dialog>
   <el-dialog title="Add Driver Record" v-model="chassisFormShow" v-on:close="resetForm()" width="30%" center draggable>
     <el-form :model="chassisForm" label-position="top" label-width="200px">
       <el-row>
         <el-form-item :label="$t('msg.track.smsId')" prop="smsId">
           <el-input v-model="chassisForm.smsId" disabled></el-input>
         </el-form-item>
         <el-form-item :label="$t('msg.track.ctnrId')" prop="ctnrId">
           <el-input v-model="chassisForm.ctnrId" style="margin-left: 20px" disabled></el-input>
         </el-form-item>
       </el-row>
       <el-row>
         <el-form-item :label="$t('msg.dray.chassisId')" prop="chassisId">
           <el-input v-model="chassisForm.chassisId"></el-input>
         </el-form-item>
         <el-form-item :label="$t('msg.account.appTime')" prop="appTime">
           <el-date-picker v-model="chassisForm.appTime" type="datetime" format="YYYY/MM/DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss" style="margin-left: 20px"></el-date-picker>
         </el-form-item>
       </el-row>
       <el-row>
         <el-form-item :label="$t('msg.account.driverName')" prop="driverName" required>
           <el-select v-model="chassisForm.driverName">
             <el-option label="Dai" value="Dai"></el-option>
             <el-option label="Fan" value="Fan"></el-option>
             <el-option label="David" value="David"></el-option>
             <el-option label="Ricky" value="Ricky"></el-option>
             <el-option label="Eric" value="Eric"></el-option>
             <el-option label="James" value="James"></el-option>
           </el-select>
         </el-form-item>
         <el-form-item :label="$t('msg.account.srvType')" prop="srvType" required style="margin-left: 20px">
           <el-select v-model="chassisForm.srvType">
             <el-option label="Terminal Pickup" value="pickup"></el-option>
             <el-option label="Delivery" value="delivery"></el-option>
             <el-option label="Empty Pickup" value="emptyPickup"></el-option>
             <el-option label="Empty Return" value="emptyReturn"></el-option>
           </el-select>
         </el-form-item>
       </el-row>
       <el-row>
         <el-form-item :label="$t('msg.account.beginTime')" prop="beginTime" required >
           <el-date-picker v-model="chassisForm.beginTime" type="datetime" format="YYYY/MM/DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
         </el-form-item>
         <el-form-item :label="$t('msg.account.endTime')" prop="endTime" required style="margin-left: 20px">
           <el-date-picker v-model="chassisForm.endTime" type="datetime" format="YYYY/MM/DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
         </el-form-item>
       </el-row>
       <el-row>
         <el-form-item :label="$t('msg.account.waitBegin')" prop="waitBegin" >
           <el-date-picker v-model="chassisForm.waitBegin" type="datetime" format="YYYY/MM/DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
         </el-form-item>
         <el-form-item :label="$t('msg.account.waitEnd')" prop="waitEnd" style="margin-left: 20px">
           <el-date-picker v-model="chassisForm.waitEnd" type="datetime" format="YYYY/MM/DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
         </el-form-item>
       </el-row>
       <el-row>
         <el-form-item :label="$t('msg.account.mileage')" prop="mileage" required>
           <el-input-number v-model="chassisForm.mileage" :step="1" controls-position="right"></el-input-number>
         </el-form-item>
         <el-form-item :label="$t('msg.track.termLFD')" prop="lfd" style="margin-left: 20px">
           <el-date-picker v-model="portInfoForDray.lfd" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
         </el-form-item>
       </el-row>
     </el-form>
     <el-button type="primary" v-on:click="recordSubmit()" style="float: right">Submit</el-button>
   </el-dialog>
   <el-dialog title="Update Information" v-model="updateInfoShow" v-on:close="resetInfoForm()" width="30%" center draggable>
     <el-form :model="portInfoForDray" label-position="top" label-width="200px">
       <el-row>
         <el-form-item :label="$t('msg.track.fullGateOut')" prop="outGateTime" required >
           <el-date-picker v-model="portInfoForDray.outGateTime" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
         </el-form-item>
         <el-form-item :label="$t('msg.track.emptyReturn')" prop="emptyReturnTime" required style="margin-left: 20px">
           <el-date-picker v-model="portInfoForDray.emptyReturnTime" format="YYYY/MM/DD" value-format="YYYY-MM-DD"></el-date-picker>
         </el-form-item>
       </el-row>
     </el-form>
     <el-button type="primary" v-on:click="infoSubmit()" style="float: right">Submit</el-button>
   </el-dialog>
 </div>
</template>

<script>
import Markdown from './components/Markdown.vue'
import { Delete, View } from '@element-plus/icons-vue'
import { defineComponent, onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import axios from 'axios'
import { getSum } from '@/utils/billEditTempOp'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'drayDetail',
  components: { Markdown },
  props: {
    refId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const activeName = ref('Order Summary')
    const drayOrder = ref({ id: 0, sslCarrier: '', mbl: '', ctnrId: '', ctnrSize: '', sealNumber: 'NA', vesselETA: '', ctnrWeight: 0, wtUOM: 'lb', port: '', streetOne: '', streetTwo: '', city: '', state: '', zip: '', attr: '' })
    const portInfoForDray = ref({ ctnrId: '', bookId: '', mbl: '', client: '', pod: '', firmsCode: '', dischargeTime: '', appointTime: '', lfd: '', outGateTime: '', whDeliveryAppointTime: '', actualArrivalTime: '', emptyNotifyTime: '', emptyPickUpTime: '', emptyReturnAppointTime: '', emptyReturnTime: '', drayCompany: '', oswCompany: '', warehouseName: '', ctnrSize: '', weight: 0, primeKey: '' })
    const interimAP = ref([])
    const interimAR = ref([])
    const basicInfoList = ref([])
    const driverRecordList = ref([])
    const drayRate = ref([])
    const orderRecord = ref({ id: 0, serviceProvider: '', client: '', orderSector: '', csopName: '', createTime: '', payTerm: '', shipStatus: '', billStatus: '', clientCode: '' })
    const standardParam = ref('')
    const totalAP = ref(0)
    const totalAR = ref(0)
    const grossProfit = ref(0)
    const editButtonShow = ref(true)
    const otherButtonShow = ref(false)
    const apFlag = ref(0)
    const arFlag = ref(0)
    const invEditFlag = ref(false)
    const uploadHandleURL = ref('')
    const myUpload = ref()
    const active = ref(0)
    const viewIcon = View
    const delIcon = Delete
    const markDown = Markdown
    const msgTitle = ref('')
    const msgDetail = ref({})
    const uploadShow = ref(false)
    const uploadForm = ref({ refId: '', docCategory: '', uploadList: [] })
    const chassisFormShow = ref(false)
    const chassisForm = ref({ ctnrId: '', chassisId: '', driverName: '', srvType: '', appTime: '', beginTime: '', endTime: '', waitBegin: '', waitEnd: '', mileage: 0, smsId: '' })
    const billFormShow = ref(false)
    const billForm = ref({ item: '', remark: '', qty: 0, rate: 0, amount: 0, adjustAmount: 0, adjustReason: '', finalAmount: 0 })
    const billTemp = ref([{ item: '', remark: '', qty: 0, rate: 0, amount: 0, adjustAmount: 0, adjustReason: '', finalAmount: 0 }])
    const allDrayRates = ref([])
    const multipleSelection = ref([])
    const addToAR = ref('N')
    const updateInfoShow = ref(false)
    const infoForm = ref({ lfd: '', dueDate: '' })
    const curUser = localStorage.getItem('curUser')
    const onSuccess = () => {
      msgTitle.value = ''
    }
    const todayInForm = () => {
      return dayjs(new Date()).format('YYYY-MM-DD')
    }
    const fileUpload = () => {
      uploadShow.value = true
      uploadHandleURL.value = process.env.VUE_APP_BASE + '/dray/imageUpload'
    }
    const handleChange = (file, fileList) => {
      uploadForm.value.uploadList = fileList
    }
    const submitUpload = () => {
      const formData = new FormData()
      formData.append('myParam', props.refId)
      formData.append('docCategory', uploadForm.value.docCategory)
      formData.append('file', uploadForm.value.uploadList[0].raw)
      axios.post(uploadHandleURL.value, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        basicInfoList.value = response.data
        uploadForm.value = { refId: '', docCategory: '', uploadList: [] }
        uploadShow.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    const imageView = (row) => {
      const param = standardParam.value + '&fileName=' + row.value
      const reqURL = process.env.VUE_APP_BASE + '/dray/imageView?' + param
      window.open(reqURL)
    }
    const delHandle = (row) => {
      const param = standardParam.value + '&fileName=' + row.value
      const reqURL = process.env.VUE_APP_BASE + '/dray/imageDel'
      axios.post(reqURL, param).then((response) => {
        basicInfoList.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const resetForm = () => {
      chassisForm.value = { ctnrId: '', chassisId: '', driverName: '', srvType: '', appTime: '', beginTime: '', endTime: '', waitBegin: '', waitEnd: '', mileage: 0, smsId: '' }
    }
    const resetInfoForm = () => {
      infoForm.value = { lfd: '', dueDate: '' }
    }
    const infoSubmit = () => {
      console.log('infoFormDataSubmit')
    }
    const openUpdate = () => {
      updateInfoShow.value = true
    }
    const getDrayRateAR = () => {
      const param = standardParam.value
      const reqURL = process.env.VUE_APP_BASE + '/dray/getDrayRateAR'
      axios.post(reqURL, param).then((response) => {
        billTemp.value = response.data
        localStorage.setItem('myDrayRates', JSON.stringify(response.data))
      }).catch((response) => {
        console.log(response)
      })
    }
    const getInterimAP = (billType) => {
      const param = standardParam.value + '&billType=' + billType
      const reqURL = process.env.VUE_APP_BASE + '/dray/getInterimAPAR'
      axios.post(reqURL, param).then((response) => {
        if (billType === 'AP') {
          interimAP.value = response.data
        } else {
          interimAR.value = response.data
        }
      }).catch((response) => {
        console.log(response)
      })
    }
    const addAP = (billType) => {
      if ((billType === 'AP' && apFlag.value !== 0) || (billType === 'AR' && arFlag.value !== 0)) {
        alert('Please Click Confirm or Cancel of the opened row')
        return false
      }
      getDrayRateAR()
      if (billType === 'AP') {
        interimAP.value.push({ item: '', remark: '', qty: 0, rate: 0, amount: 0, adjustAmount: 0, adjustReason: '', finalAmount: 0 })
        apFlag.value = -(interimAP.value.length)
      } else {
        interimAR.value.push({ item: '', remark: '', qty: 0, rate: 0, amount: 0, adjustAmount: 0, adjustReason: '', finalAmount: 0 })
        arFlag.value = -(interimAR.value.length)
      }
    }
    const editAP = (id, billType) => {
      if ((billType === 'AP' && apFlag.value !== 0) || (billType === 'AR' && arFlag.value !== 0)) {
        alert('Please Click Confirm or Cancel of the opened row')
        return false
      }
      getInterimAP(billType)
      getDrayRateAR()
      if (billType === 'AP') {
        apFlag.value = -(id + 1)
      } else {
        arFlag.value = -(id + 1)
      }
    }
    const cancelUpdateAP = (billType) => {
      if (billType === 'AP') {
        apFlag.value = 0
      } else {
        arFlag.value = 0
      }
      getInterimAP(billType)
    }
    const getItem = (id, billType) => {
      allDrayRates.value = JSON.parse(localStorage.getItem('myDrayRates'))
      const list = billType === 'AP' ? interimAP.value : interimAR.value
      const selectedRemark = []
      const myRemark = list[id].remark
      if (myRemark === 'Other') {
        selectedRemark.push({ item: '', remark: 'Other', qty: 0, rate: 0, amount: 0, adjustAmount: 0, adjustReason: '', finalAmount: 0 })
      } else {
        for (let n = 0; n < allDrayRates.value.length; n++) {
          if (allDrayRates.value[n].remark === myRemark) {
            selectedRemark.push(allDrayRates.value[n])
          }
        }
      }
      billTemp.value = selectedRemark
      list[id] = selectedRemark.length > 0 ? selectedRemark[0] : { item: '', remark: myRemark, qty: 0, rate: 0, amount: 0, adjustAmount: 0, adjustReason: '', finalAmount: 0 }
    }
    const handleItemChange = (id, billType) => {
      allDrayRates.value = JSON.parse(localStorage.getItem('myDrayRates'))
      const list = billType === 'AP' ? interimAP.value : interimAR.value
      for (let n = 0; n < allDrayRates.value.length; n++) {
        if (allDrayRates.value[n].item === list[id].item) {
          list[id] = allDrayRates.value[n]
          return
        }
      }
    }
    const qtyChange = (id, billType) => {
      if (billType === 'AP') {
        interimAP.value[id].finalAmount = interimAP.value[id].qty * interimAP.value[id].rate
      } else {
        interimAR.value[id].finalAmount = interimAR.value[id].qty * interimAR.value[id].rate
      }
    }
    const addBillItem = (id, billType) => {
      apFlag.value = 0
      arFlag.value = 0
      const list = billType === 'AP' ? interimAP.value : interimAR.value
      if (orderRecord.value.billStatus !== 'NA' && orderRecord.value.billStatus !== 'AR') {
        addToAR.value = 'N'
      }
      const apar = addToAR.value === 'Y' ? 'APAR' : billType
      const param = standardParam.value + '&billTemp=' + JSON.stringify(list[id]) + '&addToAR=' + apar
      const reqURL = process.env.VUE_APP_BASE + '/dray/addBillItem'
      axios.post(reqURL, param).then((response) => {
        alert(response.data)
        initPage()
      }).catch((response) => {
        console.log(response)
      })
      billFormShow.value = false
    }
    const addRecord = () => {
      chassisForm.value.ctnrId = portInfoForDray.value.ctnrId
      chassisForm.value.smsId = portInfoForDray.value.primeKey
      chassisForm.value.chassisId = getChassisId(driverRecordList.value)
      chassisFormShow.value = true
    }
    const editDriverRecord = (row) => {
      chassisForm.value = row
      chassisFormShow.value = true
    }
    const recordSubmit = () => {
      if (chassisForm.value.chassisId === '' || chassisForm.value.chassisId === 'Not Available' || chassisForm.value.driverName === '' || chassisForm.value.srvType === '' || chassisForm.value.beginTime === '' || chassisForm.value.endTime === '' || portInfoForDray.value.lfd === '' || !portInfoForDray.value.lfd.includes('-')) {
        alert('lfd, chassisId,driverName,srvType,beginTime and endTime in this form are required!')
        return false
      }
      const param = standardParam.value + '&newRecord=' + JSON.stringify(chassisForm.value) + '&curUser=' + curUser + '&lfd=' + portInfoForDray.value.lfd +
        '&outGate=' + portInfoForDray.value.outGateTime + '&actualArrival=' + portInfoForDray.value.actualArrivalTime + '&emptyReturn=' + portInfoForDray.value.emptyReturnTime
      const reqURL = process.env.VUE_APP_BASE + '/dray/addDriverRecord'
      axios.post(reqURL, param).then((response) => {
        portInfoForDray.value = response.data.portInfoForDray
        driverRecordList.value = response.data.driverRecordList
        interimAP.value = response.data.interimAP
        interimAR.value = response.data.interimAR
        totalAP.value = getSum(interimAP.value)
        totalAR.value = getSum(interimAR.value)
        grossProfit.value = totalAR.value - totalAP.value
        chassisFormShow.value = false
        resetForm()
      }).catch((response) => {
        console.log(response)
      })
    }
    const createInvoice = () => {
      if (portInfoForDray.value.outGateTime.length < 6 || portInfoForDray.value.emptyReturnTime < 6) {
        alert('OutGate and EmptyReturn Time are required, please add driver records!')
        return false
      }
      const param = 'primeKey=' + props.refId + '&curName=' + curUser + '&drayOrder=' + JSON.stringify(drayOrder.value) + '&interimAR=' +
        JSON.stringify(interimAR.value) + '&portInfoForDray=' + JSON.stringify(portInfoForDray.value) + '&total=' + totalAR.value
      const reqURL = process.env.VUE_APP_BASE + '/dray/createInvoice'
      axios.post(reqURL, param).then((response) => {
        orderRecord.value.billStatus = response.data
      }).catch((response) => {
        console.log(response)
      })
    }
    const getInvoice = () => {
      const param = standardParam.value + '&ctnrId=' + drayOrder.value.ctnrId
      const reqURL = process.env.VUE_APP_BASE + '/dray/viewInvoice?' + param
      window.open(reqURL)
    }
    const rcReview = (rcType) => {
      const myParam = 'spot;' + standardParam.value + '&rcType=' + rcType + ';view'
      router.push(`/drayRC/drayRCEdit/${myParam}`)
    }
    const initPage = () => {
      const param = 'primeKey=' + props.refId + '&curName=' + curUser
      const reqURL = process.env.VUE_APP_BASE + '/getDrayOrderDetail'
      axios.post(reqURL, param).then((response) => {
        drayOrder.value = response.data.drayOrder
        portInfoForDray.value = response.data.portInfoForDray
        interimAP.value = response.data.interimAP
        interimAR.value = response.data.interimAR
        basicInfoList.value = response.data.basicInfoList
        driverRecordList.value = response.data.driverRecordList
        orderRecord.value = response.data.orderRecord
        standardParam.value = 'myNewId=' + orderRecord.value.id + '&orderSector=' + orderRecord.value.orderSector + '&csopName=' + orderRecord.value.csopName
        totalAP.value = getSum(interimAP.value)
        totalAR.value = getSum(interimAR.value)
        grossProfit.value = totalAR.value - totalAP.value
      }).catch((response) => {
        console.log(response)
      })
      setActIndex()
    }
    onMounted(() => {
      initPage()
    })
    function setActIndex () {
      if (portInfoForDray.value.emptyReturnTime != null && portInfoForDray.value.emptyReturnTime < todayInForm()) {
        active.value = 4
      } else if (portInfoForDray.value.actualArrivalTime != null && portInfoForDray.value.actualArrivalTime < todayInForm()) {
        active.value = 3
      } else if (portInfoForDray.value.outGateTime != null && portInfoForDray.value.outGateTime < todayInForm()) {
        active.value = 2
      } else if (portInfoForDray.value.dischargeTime != null && portInfoForDray.value.dischargeTime < todayInForm()) {
        active.value = 1
      } else {
        active.value = 0
      }
    }
    function getChassisId(list) {
      return list.length > 0 ? list[0].chassisId : 'Not Available'
    }
    return { infoSubmit, openUpdate, resetInfoForm, infoForm, updateInfoShow, drayRate, billFormShow, billForm, billTemp, addToAR, multipleSelection, allDrayRates, arFlag, markDown, msgTitle, msgDetail, invEditFlag, rcReview, onSuccess, createInvoice, getInvoice, qtyChange, getItem, getInterimAP, cancelUpdateAP, handleItemChange, addBillItem, drayOrder, driverRecordList, chassisFormShow, chassisForm, activeName, portInfoForDray, orderRecord, active, apFlag, editButtonShow, otherButtonShow, resetForm, addAP, editAP, addRecord, setActIndex, recordSubmit, editDriverRecord, viewIcon, delIcon, myUpload, imageView, delHandle, handleChange, submitUpload, uploadHandleURL, uploadShow, interimAP, interimAR, totalAP, totalAR, grossProfit, uploadForm, basicInfoList, fileUpload }
  }
})
</script>

<style scoped>

</style>
